import { Box } from "@mui/material"

const validateData = (data, dataClient) => {

    const response = []

    if (!dataClient?.datetime)
        response.push(<Box>Selecione a data.</Box>)

    if (response.length === 0)
        return ''
    return (response)
}

export default validateData