import React, { useContext, useReducer, useEffect } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, Autocomplete, ListItem, List } from '@mui/material';
import ColorButton from '../Buttons/ColorButton';
import { NumbersOnly } from '../Utils/IntegerOnly';
import { PhoneMask, CnpfMask, CepMask } from '../Utils/MaskedInput';
import DefaultModal from '../Utils/DefaultModal';
import { lockedReducer } from '../Utils/reducers';
import { externalComponent } from '../AppRoutes';
import IntegerOnly from '../Utils/IntegerOnly';
import './styles.css'
import { api } from '../../services/api';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { number2dayweek } from '../Utils/dayjsptbrhelper';
import { NumericFormatInput } from '../Utils/MaskedInput';

import { downloadFile } from '../../services/api';
import { defaultBackend } from '../../services/api';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MegaAutoComplete from '../Utils/megaautocomplete';
import validateData from './validateData';

const originURL = "https://www.nfe.fazenda.gov.br"
const originHref = "/portal/consultaRecaptcha.aspx"
const originResumo = "/portal/consultaResumo.aspx"
const receitaURL = originURL + originHref
const resumoURL = originURL + originResumo
var eventReceived = false
var consultReceived = false
var childWindow = null

const url = '/serviceorder/'
const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}
const submit = async (state, stateClient, setState, data, setLoader, handleClose, reloadData) => {
    setLoader(true)

    var obj = {
        client: stateClient,
        service_order: state
    }

    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, obj)
    else
        res = await api.post(url, obj)

    setLoader(false)

    if (res.status === 201 || res.status === 200) {
        toast.success(`Ordem de serviço ${res.status === 201 ? 'adicionada' : 'editada'} com sucesso`)
        reloadData()
        handleClose()
        return;
    }

    toast.error(`error`);
}

export const generateReport = async (id, setLoader) => {
    setLoader(true)
    const res = await api.post(url + `${id}/generate/`)
    if (res.status === 200) {
        await downloadFile(defaultBackend.replace('api/', '') + res.data + '/')
    }
    setLoader(false)
}


const paymentInfoHandle = (infos, setState) => {
    var result = infos?.data || {}

    setState({
        payment_data: {
            name: result['name'] || '',
            first_parcel: result['first_parcel'] || true,
            num_days: result['num_days'] || 1,
            parcel_count: result['parcel_count'] || 1,
            parcel_fix: result['parcel_fix'] || true,
            upfront: result['upfront'],
            discount: 0,
            addition: 0
        }
    })
}
const clientInfoHandle = (infos, setStateClient) => {
    var result = infos?.data || {}

    setStateClient({
        datetime: result['datetime'] || '',
        name: result['name'] || '',
        email: result['email'] || '',
        phone_number: result['phone_number'] || '',
        phone_cell: result['phone_cell'] || '',
        phone_commercial: result['phone_commercial'] || '',
        cep: result['cep'] || '',
        street: result['street'] || '',
        neighborhood: result['neighborhood'] || '',
        complement: result['complement'] || '',
        city: result['city'] || '',
        uf: result['uf'] || '',
        street_number: result['street_number'] || '',
        cpf_cnpj: result['cpf_cnpj'] || '',
        rg: result['rg'] || '',
        type: 'CLIENT' || '',

    })
}

const loadDataClient = async (id, setState, setLoader, setStateClient) => {
    setLoader(true)

    const res = await api.get(url + `${id}/`)
    setLoader(false)
    if (res.status === 200) {
        setStateClient({ ...res.data.client })
        return
    }
}

const serviceDatetime = (service, e) => {
    service.datetime = e
}
const EditServiceOrderModal = ({ reloadData, readOnly, handleClose, data }) => {
    const { setLoader } = useContext(externalComponent)
    const [stateClient, setStateClient] = useReducer(lockedReducer, {
        datetime: '',
        name: '',
        email: '',
        phone_number: '',
        phone_cell: '',
        phone_commercial: '',
        cep: '',
        street: '',
        neighborhood: '',
        complement: '',
        city: '',
        uf: '',
        street_number: '',
        cpf_cnpj: '',
        rg: '',
        type: 'CLIENT',
        corporate_name: '',
        id: data?.id || 0,
    })
    const [state, setState] = useReducer(lockedReducer, {
        vehicle_data: {
            owner: '',
            resp_financial: '',
            vehicle_city: '',
            license_plate: '',
            renavam: '',
            chassi: '',
            brand_model: '',
            color: '',
            fab_year: '',
            model_year: '',
            fuel: ''
        },
        service_number: '',
        loaded_payments: '',
        loaded_clients: '',
        payments: '',
        service_data: [],
        services: [],
        payment_data: {
            name: '',
            parcel_fix: true,
            first_parcel: true,
            parcel_count: 1,
            num_days: 1,
            upfront: true,
            addition: 0,
            discount: 0,
        },
        expanded: false,
        nfemodal: null,
        editSchedule: null,
        observations: '',
        total_value: 0,
        id: data?.id || 0,
        ...data

    })

    const handleExpansion = () => {
        setState({ expanded: (prevExpanded) => !prevExpanded });
    };

    const validData = validateData(state, stateClient)

    const content = (<>
        {/* Client  */}
        <Accordion
            onChange={handleExpansion}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography>Pessoa</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <Box className="item-content-modal noborder">
                        <Box className="item-profile-row">
                            <MegaAutoComplete
                                value={state.loaded_clients}
                                onChange={(data) => { setState({ loaded_clients: data }); clientInfoHandle(data, setStateClient) }}
                                noOptionsText={`Digite para procurar pessoas`}
                                timeoutUrl={'/client/complete/'}
                                timeoutParam={'name'}
                                label={'Pessoas'}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            {!readOnly && <FormControl sx={{ minWidth: 120 }} size='small' >
                                <InputLabel id="select-type-label">Tipo</InputLabel>
                                <Select
                                    labelId="select-type-label"
                                    id="select-type"
                                    label="Tipo"
                                    value={state.type}
                                    onChange={(e) => { setState({ 'type': e.target.value }) }}
                                >
                                    {/* {types.map(([key, value]) =>
                        <MenuItem value={key}>{value}</MenuItem>
                    )} */}

                                </Select>
                            </FormControl>}
                            <TextField
                                sx={{ flex: 1 }}
                                id="name"
                                type="text"
                                label="Nome"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.name}
                                onChange={(e) => setStateClient({ 'name': e.target.value })}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                        </Box>

                        <Box className="item-profile-row">
                            <TextField
                                sx={{ flex: .8 }}
                                id="corporate_name"
                                type="text"
                                label="Razão Social"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.corporate_name}
                                onChange={(e) => { setStateClient({ 'corporate_name': e.target.value }) }}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        </Box>
                        <Box className='item-profile-row'>
                            <MobileDatePicker
                                size='small'
                                label='Data de nascimento'
                                value={dayjs(stateClient.datetime)}
                                format='DD/MM/YYYY'
                                sx={{ flex: 1 }}
                                onChange={(e) => { setStateClient({ 'datetime': e }) }}
                                slotProps={{ textField: { size: 'small' } }}
                                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                        </Box>
                        <Box className='item-profile-row'>
                            <TextField
                                id="cpf_cnpj"
                                type="text"
                                label="CPF/CNPJ"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.cpf_cnpj}
                                onChange={(e) => setStateClient({ 'cpf_cnpj': NumbersOnly(e.target.value) })}
                                fullWidth={false}
                                InputProps={{
                                    inputComponent: CnpfMask
                                }}
                            />

                            <TextField
                                id="rg"
                                type="text"
                                label="RG"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.rg}
                                onChange={(e) => setStateClient({ 'rg': e.target.value })}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 25
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextField
                                sx={{ flex: 1 }}
                                id="email"
                                type="text"
                                label="Email"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.email}
                                onChange={(e) => setStateClient({ 'email': e.target.value })}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextField
                                sx={{ flex: 1 }}
                                id="phone_number"
                                type="text"
                                label="Telefone"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.phone_number}
                                onChange={(e) => setStateClient({ 'phone_number': NumbersOnly(e.target.value) })}
                                fullWidth={false}
                                InputProps={{
                                    inputComponent: PhoneMask
                                }}
                            />
                            <TextField
                                sx={{ flex: 1 }}
                                id="phone_cell"
                                type="text"
                                label="Celular"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.phone_cell}
                                onChange={(e) => setStateClient({ 'phone_cell': NumbersOnly(e.target.value) })}
                                fullWidth={false}
                                InputProps={{
                                    inputComponent: PhoneMask
                                }}
                            />
                            <TextField
                                sx={{ flex: 1 }}
                                id="phone_commercial"
                                type="text"
                                label="Telefone"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.phone_commercial}
                                onChange={(e) => setStateClient({ 'phone_commercial': NumbersOnly(e.target.value) })}
                                fullWidth={false}
                                InputProps={{
                                    inputComponent: PhoneMask
                                }}
                            />
                        </Box>

                        <Box sx={{ display: "flex", gap: 1 }}>
                            <TextField
                                sx={{ flex: .5 }}
                                id="cep"
                                type="text"
                                label="CEP"
                                disabled={readOnly}
                                value={stateClient.cep}
                                onChange={(e) => setStateClient({ 'cep': NumbersOnly(e.target.value) })}
                                fullWidth={false}
                                size='small'
                                InputProps={{
                                    inputComponent: CepMask
                                }}
                            />
                            <TextField
                                sx={{ flex: 1 }}
                                type="text"
                                id="complement"
                                disabled={readOnly}
                                label="Complemento"
                                value={stateClient.complement}
                                size='small'
                                fullWidth={false}
                                onChange={(e) => setStateClient({ 'complement': e.target.value })}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextField
                                sx={{ flex: 1 }}
                                id="street"
                                type="text"
                                label="Rua"
                                size='small'
                                disabled={readOnly}
                                value={stateClient.street}
                                onChange={(e) => setStateClient({ street: e.target.value })}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <TextField
                                sx={{ flex: .2 }}
                                id="street_number"
                                type="text"
                                label="Número"
                                disabled={readOnly}
                                size='small'
                                value={stateClient.street_number}
                                onChange={(e) => setStateClient({ 'street_number': e.target.value })}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }} >
                            <TextField
                                sx={{ flex: 1 }}
                                id="neighborhood"
                                type="text"
                                label="Bairro"
                                disabled={readOnly}
                                value={stateClient.neighborhood}
                                onChange={(e) => setStateClient({ 'neighborhood': e.target.value })}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <TextField
                                sx={{ flex: 1 }}
                                id="city"
                                type="text"
                                label="Cidade"
                                disabled={readOnly}
                                value={stateClient.city}
                                onChange={(e) => setStateClient({ 'city': e.target.value })}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <TextField
                                sx={{ flex: .25 }}
                                id="uf"
                                type="text"
                                label="UF"
                                disabled={readOnly}
                                value={stateClient.uf}
                                onChange={(e) => setStateClient({ 'uf': e.target.value })}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                        </Box>
                    </Box>
                </Typography>
            </AccordionDetails>
        </Accordion>

        {/* Vehicle */}
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <Typography>Veículo</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <Box sx={{ marginBottom: '2rem' }} className="item-content-modal noborder">
                        <Box className="item-content-modal noborder">
                            <TextField
                                sx={{ flex: .5 }}
                                id="owner"
                                type="text"
                                label="Proprietário"
                                disabled={readOnly}
                                value={state.vehicle_data.owner}
                                fullWidth={false}
                                onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'owner': e.target.value } }) }}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <TextField
                                sx={{ flex: .5 }}
                                id="resp_financial"
                                type="text"
                                label="Responsável financeiro"
                                disabled={readOnly}
                                value={state.vehicle_data.resp_financial}
                                onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, resp_financial: e.target.value } }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <TextField
                                sx={{ flex: .5 }}
                                id="vehicle_city"
                                type="text"
                                label="Município do veículo"
                                disabled={readOnly}
                                value={state.vehicle_data.vehicle_city}
                                onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'vehicle_city': e.target.value } }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <Box className="item-profile-row">
                                <TextField
                                    id="license_plate"
                                    type="text"
                                    label="Placa"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.vehicle_data.license_plate}
                                    onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'license_plate': e.target.value } }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="renavam"
                                    type="text"
                                    label="RENAVAM"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.vehicle_data.renavam}
                                    onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'renavam': NumbersOnly(e.target.value) } }) }}
                                    fullWidth={false}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="chassi"
                                    type="text"
                                    label="Chassi"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.vehicle_data.chassi}
                                    onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'chassi': e.target.value } }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Box>

                            <TextField
                                sx={{ flex: 1 }}
                                id="brand_model"
                                type="text"
                                label="Marca/Modelo"
                                disabled={readOnly}
                                size='small'
                                value={state.vehicle_data.brand_model}
                                onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'brand_model': e.target.value } }) }}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />

                            <TextField
                                sx={{ flex: .7 }}
                                id="color"
                                type="text"
                                label="Cor"
                                disabled={readOnly}
                                size='small'
                                value={state.vehicle_data.color}
                                onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'color': e.target.value } }) }}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="fab_year"
                                    type="text"
                                    label="Fab/Mod"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.vehicle_data.fab_year}
                                    onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'fab_year': NumbersOnly(e.target.value) } }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="model_year"
                                    type="text"
                                    label="Modelo"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.vehicle_data.model_year}
                                    onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'model_year': NumbersOnly(e.target.value) } }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="fuel"
                                    type="text"
                                    label="Combustível"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.vehicle_data.fuel}
                                    onChange={(e) => { setState({ vehicle_data: { ...state.vehicle_data, 'fuel': e.target.value } }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>

                        </Box>
                    </Box>
                </Typography>
            </AccordionDetails>
        </Accordion>

        {/* Service */}
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <Typography>Serviço</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <Box className="item-content-modal noborder">
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <MegaAutoComplete
                                multiple={true}
                                value={state.service_data}
                                onChange={(data) => setState({ service_data: data })}
                                noOptionsText={`Digite para procurar Serviços`}
                                timeoutUrl={'/service/complete/'}
                                timeoutParam={'name'}
                                label={'Serviço'}
                            />
                        </Box>
                        {state.service_data.length > 0 &&
                            <>
                                <List className='product-service-list'>
                                    {state.service_data?.map((service, index) => (
                                        <Box className='product-service-list-wrapper'>
                                            <ListItem className='nopadding' key={`service-listitem${service.id}`} title={service.label}>
                                                <Box className='simple-label'>
                                                    <Box>{service.label}</Box>
                                                    <Box sx={{ flex: 1 }} className='single-line'></Box>
                                                </Box>
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    size='small'
                                                    label='Valor.'
                                                    className='simple-value'
                                                    value={service.data?.value}
                                                    inputProps={{
                                                        maxLength: 13
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumericFormatInput
                                                    }}
                                                    onChange={(e) => { service.data.value = e.target.value; setState({ service_data: [...state.service_data] }) }} />
                                                <MobileDatePicker
                                                    sx={{ flex: .3 }}
                                                    size='small'
                                                    label='Data de vencimento'
                                                    value={dayjs(service.data?.datetime)}
                                                    format='DD/MM/YYYY'
                                                    onChange={(e) => { service.data.datetime = dayjs(e); setState({ service_data: [...state.service_data] }) }}
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                                    ampm={false}
                                                />
                                            </ListItem>
                                        </Box>))}
                                </List>

                                <Box sx={{ display: 'flex' }}>
                                    <TextField
                                        sx={{ flex: 1 }}
                                        size='small'
                                        label='Observações'
                                        value={state.observations}
                                        inputProps={{
                                            maxLength: 13
                                        }}
                                        onChange={(e) => setState({ observations: e.target.value })} />
                                </Box>
                            </>}
                    </Box>
                </Typography>
            </AccordionDetails>
        </Accordion>

        {/* Payment */}
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <Typography>Pagamento</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <Box className='item-content-modal noborder' >

                        <MegaAutoComplete
                            value={state.loaded_payments}
                            onChange={(data) => { setState({ loaded_payments: data }); paymentInfoHandle(data, setState) }}
                            noOptionsText={`Digite para procurar pagamentos`}
                            timeoutUrl={'/payment/complete/'}
                            timeoutParam={'name'}
                            label={'Pagamentos'}
                        />
                        <Box className='item-profile-row'>
                            <TextField
                                id="name"
                                type="text"
                                label="Nome"
                                value={state.payment_data?.name}
                                onChange={(e) => { setState({ payment_data: { ...state.payment_data, 'name': e.target.value } }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 50
                                }}
                                sx={{ flex: '.68 !important' }}
                            />
                            <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre', flex: '.32 !important' }}>
                                <ColorButton reverse={!state.payment_data?.upfront} onClick={() => setState(
                                    {
                                        payment_data: {
                                            ...state.payment_data,
                                            upfront: true,
                                            parcel_count: 1,
                                            num_days: 1,
                                            parcel_fix: true
                                        }
                                    }

                                )}>À vista</ColorButton>
                                <ColorButton reverse={state.payment_data?.upfront} onClick={() => setState({ payment_data: { ...state.payment_data, upfront: false } })}>À Prazo</ColorButton>
                            </Box>
                        </Box>
                        <Box className='item-profile-row'>
                            <TextField
                                id="parcel_count"
                                type="text"
                                label="Parcelas"
                                disabled={state.payment_data?.upfront}
                                value={state.payment_data?.parcel_count}
                                onChange={(e) => { setState({ payment_data: { ...state.payment_data, 'parcel_count': IntegerOnly(e.target.value, 1, 100) } }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                            <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre' }}>
                                <ColorButton disabled={state.payment_data?.upfront} reverse={!state.payment_data?.parcel_fix} onClick={() => setState({ payment_data: { ...state.payment_data, parcel_fix: true } })}>N° Fixo</ColorButton>
                                <ColorButton disabled={state.payment_data?.upfront} reverse={state.payment_data?.parcel_fix} onClick={() => setState({ payment_data: { ...state.payment_data, parcel_fix: false } })}>N° Máximo</ColorButton>
                            </Box>
                            <TextField
                                id="num_days"
                                type="text"
                                label="N° Dias"
                                disabled={state.payment_data?.upfront}
                                value={state.payment_data?.num_days}
                                onChange={(e) => { setState({ payment_data: { ...state.payment_data, 'num_days': IntegerOnly(e.target.value) } }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        </Box>
                        <Box className='item-profile-row'>
                            <Box className='payment-switch-buttons' sx={{ whiteSpace: 'pre', flex: '.2 !important' }}>
                                <ColorButton reverse={!state.payment_data?.first_parcel} onClick={() => setState({ payment_data: { ...state.payment_data, first_parcel: true } })}>Entrada</ColorButton>
                                <ColorButton reverse={state.payment_data?.first_parcel} onClick={() => setState({ payment_data: { ...state.payment_data, first_parcel: false } })}>S/ Entrada</ColorButton>
                            </Box>

                            <TextField
                                id="addition"
                                type="text"
                                label="Acréscimo"
                                value={state.payment_data?.addition}
                                onChange={(e) => { setState({ payment_data: { ...state.payment_data, 'addition': e.target.value } }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 50
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatInput
                                }}
                                sx={{ flex: '.68 !important' }}
                            />

                            <TextField
                                id="discount"
                                type="text"
                                label="Desconto"
                                value={state.payment_data?.discount}
                                onChange={(e) => { setState({ payment_data: { ...state.payment_data, 'discount': e.target.value } }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 50
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatInput
                                }}
                                sx={{ flex: '.68 !important' }}
                            />
                        </Box>
                    </Box>
                </Typography>
            </AccordionDetails>
        </Accordion>
    </>)

    useEffect(() => {
        if (data.id) { loadDataClient(data.id, setState, setLoader, setStateClient) }
    }, [data.id])

    return (
        <>

            <DefaultModal
                title={
                    `${getTitle(state)} Ordem de serviço`
                }
                handleClose={handleClose}
                content={<>
                    {state.nfemodal &&
                        <NotaFiscalModal
                            data={state.nfemodal}
                            handleClose={() => setState({ nfemodal: null })}
                            reloadData={reloadData}
                            setParent={setState}
                            parent={state}
                            setParentClient={setStateClient}
                            parentClient={stateClient}
                        />
                    }
                    <Box className='item-content-modal noborder'>
                        {content}
                    </Box>
                </>}
                action={
                    <>
                        {data.id ? <ColorButton
                            sx={{ flex: .8 }}
                            onClick={() => generateReport(data.id, setLoader)}

                        >
                            Ver ordem de serviço
                        </ColorButton> : ''}
                        <ColorButton
                            onClick={() => { setState({ nfemodal: {} }) }}
                            sx={{ flex: .2 }}
                        >
                            NF-e

                        </ColorButton>
                        <ColorButton
                            onClick={() => submit(state, stateClient, setState, data, setLoader, handleClose, reloadData)}
                            sx={{ flex: .2 }}
                            disabled={validData}
                        >
                            Salvar
                        </ColorButton>
                    </>
                }
            />

        </>
    )
}


const NotaFiscalModal = ({ handleClose, data, setLoader, parent, setParent, readOnly, setParentClient, parentClient }) => {

    const [state, setState] = useReducer(lockedReducer, {
        inputValue: ''

    })

    const applySearch = (inputValue) => {
        eventReceived = false

        childWindow = window.open(receitaURL)

        const data = JSON.stringify({ event_name: 'test-event', value: inputValue });

        setTimeout(() => {
            if (childWindow) {
                childWindow.postMessage(data, receitaURL);
            } else {
                console.error("A childWindow não foi aberta corretamente.");
            }
        }, 500);

        trySendMessage(data, receitaURL, 3, 100, eventReceived);
    }
    const trySendMessage = (data, url, retry, interval, event) => {
        if (retry > 0 && event === false) {
            setTimeout(() => {
                if (childWindow) {
                    childWindow.postMessage(data, url);
                    trySendMessage(data, url, retry - 1, interval, event);
                } else {
                    console.error("childWindow não está disponível.");
                }
            }, interval);
        }
    }
    const handleInputChange = (e) => {
        const inputValue = NumbersOnly(e.target.value);

        setState({ 'inputValue': inputValue });
        if (inputValue.length >= 44) {
            applySearch(inputValue, handleClose)
        }
    };

    useEffect(() => {
        const handleWindowMessage = (e) => {
            if (e.origin === originURL) {
                const data = JSON.parse(e.data);

                if (data.event_name === 'eventReceived') {
                    eventReceived = true
                } else if (data.event_name === 'initConsult') {
                    const consultData = JSON.stringify({ event_name: 'consult' });
                    consultReceived = false
                    trySendMessage(consultData, resumoURL, 3, 100, consultReceived);
                } else if (data.event_name === 'consultResult') {
                    const result = data.data
                    setParentClient({
                        name: result["Dados do Emitente"]["Nome / Razão Social"],
                        email: '',
                        phone_number: result["Dados do Emitente"]["Telefone"],
                        phone_cell: '',
                        phone_commercial: '',
                        cep: result["Dados do Emitente"]["CEP"],
                        street: result["Dados do Emitente"]["Endereço"].replace(/&nbsp;/g, ' ').trim().replace(/\d+\s*$/, '').trim() || '',
                        neighborhood: result["Dados do Emitente"]["Bairro / Distrito"],
                        complement: '',
                        city: result["Dados do Emitente"]["Município"].split('\n').pop().trim() || '',
                        uf: result["Dados do Emitente"]["UF"],
                        street_number: result["Dados do Emitente"]["Endereço"].replace(/&nbsp;/g, ' ').trim().match(/\d+\s*$/)?.[0].trim() || '',
                        cpf_cnpj: result["Dados do Emitente"]["CNPJ"]
                    })
                    setParent({
                        service_number: result["Dados da NF-e"]["Número"],
                        vehicle_data: {
                            chassi: result['Detalhamento Específico dos Veículos Novos']['Chassi do veículo'],
                            renavam: result['Informações adicionais do produto']['Descrição'].split('#').find(parte => parte.trim().startsWith("RENAVAM"))?.split(':')[1].trim(),
                            resp_financial: '',
                            owner: result['Dados do Destinatário']['Nome / Razão Social'],
                            color: result['Dados dos Produtos e Serviços']['Descrição da cor'],
                            service: '',
                            model_year: result['Detalhamento Específico dos Veículos Novos']['Ano Modelo de Fabricação'],
                            fab_year: result['Detalhamento Específico dos Veículos Novos']['Ano de Fabricação'],
                            brand_model: result['Informações adicionais do produto']['Descrição'].split('#')[0].split(' - ')[1].trim(),
                            fuel: result['Informações adicionais do produto']['Descrição'].split('#').find(parte => parte.trim().startsWith("COMBUSTIVEL"))?.split(':')[1].trim(),
                            exercise: '',
                            process_control: '',
                            minuta: '',
                            observations: '',
                            obligation: '',
                            vehicle_city: result['Dados do Destinatário']['Município'].split('\n').pop().trim(),
                            license_plate: '',
                        }
                    })

                    consultReceived = true
                    if (childWindow) {
                        childWindow.close();
                        childWindow = null
                        setParent({ nfemodal: null })
                    }
                }
            }
        };

        window.addEventListener('message', handleWindowMessage);

        return () => {
            window.removeEventListener('message', handleWindowMessage);
        };

    }, [childWindow, consultReceived, setLoader, data.id]);


    return (<>
        <DefaultModal
            handleClose={handleClose}
            title={
                `Insira a NF-e`
            }
            content={<>
                <Box className='item-content-modal noborder'>
                    <TextField
                        sx={{ flex: 1 }}
                        id="inputValue"
                        type="text"
                        label="NF-e"
                        disabled={readOnly}
                        size='small'
                        value={state.inputValue}
                        onChange={(e) => handleInputChange(e)}
                        fullWidth={false}
                        inputProps={{
                            maxLength: 50
                        }}
                    />
                </Box>
            </>}
            action={
                <>

                </>
            }
        />

    </>)
}
export default EditServiceOrderModal;